const ConstantsCommon = require('../ConstantsCommon');

function BrowserAuthRenderer() {
    this.html = (selector, value) => { $(selector).html(value); };
    this.showToast = (message, isInfo) => { window.showToast(message, isInfo); };
    this.hideToast = () => { window.hideToast(); };
    this.onTx = () => { window.onTx(); };
    this.onRx = (isError) => { window.onRx(isError); };
    this.onTxWs = () => { window.onTxWs(); };
    this.onRxWs = () => { window.onRxWs(); };
    this.displayNonInviteMsg = (nonInviteErrorMsg) => {
        setTimeout(() => {
            bobbobAuth.setHtmlShowHide('#login-msg', nonInviteErrorMsg);
            $('#preloader-msg').html(nonInviteErrorMsg);
        }, ConstantsCommon.AWS_COGNITO_PKCE_UI_TIMEOUT);
    };
    this.displayLoginMsg = (errMsg) => {
        bobbobAuth.setHtmlShowHide('#login-msg', errMsg);
        $('#preloader-msg').html(errMsg);
        // window.showToast(errMsg);
    };
    this.refreshApp = () => {
        setTimeout(() => { preAppReload();window.location.href = ''; }, ConstantsCommon.AWS_COGNITO_PKCE_UI_TIMEOUT);
    };
    this.onPostLoggedIn = async () => {
        await window.doPostLoggedIn();
    };
    this.hideShowLockAppFeature = (isHide) => {
        window.hideShowLockAppFeature(isHide);
    };
}

module.exports = BrowserAuthRenderer;
